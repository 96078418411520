import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';
import { css } from '@compiled/react';

import { token } from '@atlaskit/tokens';

const inputStyle = css({
	position: 'absolute',
	opacity: 0,
	pointerEvents: 'none',
	height: token('space.300'),
	width: token('space.300'),
});

const baseLabelStyle = css({
	display: 'flex',
	cursor: 'pointer',
	borderRadius: token('border.radius.100'),
	placeContent: 'center',
	alignItems: 'center',
});

const hoverAndFocusStyle = css({
	'&:hover': {
		backgroundColor: token('color.background.accent.gray.subtlest'),
	},
});

const selectedHoverAndFocusStyles = css({
	'&:focus-within': {
		outline: `2px solid ${token('color.border.focused')}`,
		outlineOffset: 0,
		//eslint-disable-next-line @atlaskit/design-system/no-unsafe-design-token-usage -- The token value "4px" and fallback "3px" do not match and can't be replaced automatically.
		borderRadius: token('border.radius', '3px'),
	},
	'&:hover': {
		backgroundColor: token('color.background.accent.gray.subtlest'),
	},
});

const disabledStyle = css({
	cursor: 'not-allowed',
});
interface Props {
	isChecked: boolean;
	onClick: () => void;
	ToggleIcon: React.ElementType;
	disabled?: boolean;
	label: string;
}

export const PageCardAppearanceOption: FunctionComponent<Props> = ({
	isChecked,
	onClick,
	ToggleIcon,
	disabled = false,
	label,
}) => {
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const toggleFocus = useCallback(() => {
		setIsFocused(!isFocused);
	}, [isFocused]);

	return (
		<label
			css={[
				baseLabelStyle,
				disabled && disabledStyle,
				isChecked && !disabled ? selectedHoverAndFocusStyles : hoverAndFocusStyle,
			]}
		>
			<input
				type="radio"
				name="project-type-selection"
				css={inputStyle}
				checked={isChecked}
				onClick={onClick}
				onFocus={toggleFocus}
				onBlur={toggleFocus}
				aria-checked={isChecked}
				aria-label={label}
				disabled={disabled}
			/>
			{ToggleIcon && (
				<ToggleIcon
					size="medium"
					primaryColor={
						!disabled && isChecked ? token('color.text.selected') : token('color.text.subtlest')
					}
				/>
			)}
		</label>
	);
};
