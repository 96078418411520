import type { FC } from 'react';
import React from 'react';

import type { IconColor } from '@atlaskit/tokens/css-type-schema';

import { Compact24Icon } from './images/Compact24Icon';

type CompactIconProps = {
	label: string;
	primaryColor?: IconColor;
	testId?: string;
};

export const CompactIcon: FC<CompactIconProps> = ({ label, primaryColor, testId }) => {
	return React.createElement(Compact24Icon, {
		label,
		testId,
		primaryColor,
	});
};
