import React from 'react';

import type { GlyphProps } from '@atlaskit/icon/types';
import { token } from '@atlaskit/tokens';
import BacklogIcon from '@atlaskit/icon/core/backlog';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';

type IconProps = GlyphProps & {
	primaryColor?: IconColor;
	testId?: string;
};

export const List24Icon = (props: IconProps) => {
	const primaryColor = props.primaryColor || token('color.icon');
	const label = props.label || '';
	return (
		<BacklogIcon label={label} color={primaryColor} spacing="spacious" testId={props.testId} />
	);
};
