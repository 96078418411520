import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { cssMap } from '@compiled/react';
import debounce from 'lodash/debounce';

import { token } from '@atlaskit/tokens';
import { WidthObserver } from '@atlaskit/width-detector';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { PageCard } from './PageCard';
import type { PageCard as PageCardType, PageCardAppearance } from './card-types';
import { PageCardsContext } from './PageCardsContext';

const pageCardConainerStylesNav3 = cssMap({
	grid: {
		display: 'grid',
		minWidth: '295px',
		maxWidth: '1320px',
		gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
		gap: token('space.200'),
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '600px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'> div + div': {
			marginTop: token('space.100'),
		},
	},
	'compact-list': {
		splay: 'flex',
		flexDirection: 'column',
		minWidth: '600px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'& > div:first-of-type > div': {
			borderRadius: '4px 4px 0 0',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'& > div:nth-last-of-type(2) > div': {
			borderBottom: `1px solid ${token('color.border')}`,
			borderRadius: '0 0 4px 4px',
		},
	},
});

const pageCardConainerStyles = cssMap({
	grid: {
		display: 'grid',
		minWidth: '295px',
		maxWidth: '1320px',
		gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
		gap: token('space.200'),
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		minWidth: '600px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		'& > div': {
			borderRadius: '0px',
			borderBottom: 'none',
			'&:hover': {
				boxShadow: 'none',
				backgroundColor: token('color.background.accent.gray.subtlest'),
				'--secondary-text-color': token('color.text.subtle'),
			},
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'& > div:first-of-type': {
			borderRadius: '8px 8px 0 0',
			borderBottom: 'none',
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
		'& > div:nth-last-of-type(2)': {
			borderBottom: `1px solid ${token('color.border')}`,
			borderRadius: '0 0 8px 8px',
		},
	},
	'compact-list': {},
});

export type AnalyticsData = {
	source?: string;
	attributes?: { [key: string]: any };
};

type PageCardsProps = {
	appearance: PageCardAppearance;
	nodes: PageCardType[];
	analyticsData?: AnalyticsData;
};

export const PageCards: FC<PageCardsProps> = ({ appearance, nodes, analyticsData }) => {
	const [width, setWidth] = useState(0);
	const onResize = debounce(setWidth, 200, { leading: false });

	const pageCardsContextValue = useMemo(() => ({ width }), [width]);
	const isNav4Enabled = useIsNav4Enabled();

	return (
		<PageCardsContext.Provider value={pageCardsContextValue}>
			<div
				css={[
					isNav4Enabled && pageCardConainerStyles[appearance],
					!isNav4Enabled && pageCardConainerStylesNav3[appearance],
				]}
			>
				{nodes.map((node, i) => (
					<PageCard
						appearance={appearance}
						data={node}
						key={node.key || node.id}
						ranking={i}
						analyticsData={analyticsData}
					/>
				))}
				<WidthObserver setWidth={onResize} offscreen />
			</div>
		</PageCardsContext.Provider>
	);
};
