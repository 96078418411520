import type { FC } from 'react';
import React from 'react';
import { css, styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { xcss, Flex } from '@atlaskit/primitives';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import {
	ContentIcon,
	QuickActions,
	Subtitle,
	LastAction,
	StatusLozenge,
	Title,
	PageLink,
	PageCardContainer,
} from '../Items';

const listCardStylesNav3 = css({
	minWidth: '0',
	padding: token('space.200'),
	display: 'flex',
	alignItems: 'center',
});

const listCardStyles = css({
	minWidth: '0',
	padding: `${token('space.100')} ${token('space.200')}`,
	display: 'flex',
	alignItems: 'center',
});

const titleContainerNav3Styles = css({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.050'),
});

const titleContainerStyles = css({
	display: 'flex',
	alignItems: 'center',
});

const contentIconStylesNav3 = css({
	alignSelf: 'flex-start',
	margin: `${token('space.050')} ${token('space.200')} 0 0`,
});

const contentIconStyles = css({
	alignSelf: 'flex-start',
	margin: `${token('space.025')} ${token('space.200')} 0 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MainContentContainer = styled.div({
	alignSelf: 'flex-start',
	flex: '1 1 0px',
	minWidth: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleText = styled.div({
	marginRight: token('space.100'),
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const lastActionContainerStylesNav3 = xcss({
	minWidth: '100px',
	flex: '0 1 300px',
});

const lastActionContainerStyles = css({
	minWidth: '100px',
	flex: '0 1 220px',
	textAlign: 'right',
});

export const ListView: FC = () => {
	const isNav4Enabled = useIsNav4Enabled();
	// ⚠️ WARNING: Do you need to add conditional logic? Please refer to README#Views ⚠️
	return (
		<PageCardContainer>
			<PageLink />
			<div
				data-testid="page-card-list-view"
				css={[isNav4Enabled ? listCardStyles : listCardStylesNav3]}
			>
				<div css={[isNav4Enabled ? contentIconStyles : contentIconStylesNav3]}>
					<ContentIcon />
				</div>
				<MainContentContainer>
					<div css={[isNav4Enabled ? titleContainerStyles : titleContainerNav3Styles]}>
						<TitleText>
							<Title singleLine />
						</TitleText>
						<StatusLozenge />
					</div>
					<Subtitle />
				</MainContentContainer>
				{isNav4Enabled ? (
					<>
						<QuickActions hidden />
						<div css={lastActionContainerStyles}>
							<LastAction appearance="list" />
						</div>
					</>
				) : (
					<>
						<Flex xcss={lastActionContainerStylesNav3}>
							<LastAction />
						</Flex>
						<QuickActions hidden />
					</>
				)}
			</div>
		</PageCardContainer>
	);
};
