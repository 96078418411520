import React from 'react';

import type { IconColor } from '@atlaskit/tokens/css-type-schema';
import { token } from '@atlaskit/tokens';
import GridIcon from '@atlaskit/icon/core/grid';

type GridIconProps = {
	label: string;
	primaryColor?: IconColor;
	testId?: string;
};

export const Grid24Icon = (props: GridIconProps) => {
	const primaryColor = props.primaryColor || token('color.icon');
	const label = props.label || '';
	return <GridIcon color={primaryColor} spacing="spacious" label={label} testId={props.testId} />;
};
