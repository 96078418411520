import React from 'react';

import type { GlyphProps } from '@atlaskit/icon/types';
import type { IconColor } from '@atlaskit/tokens/css-type-schema';
import MenuIcon from '@atlaskit/icon/core/menu';
import { token } from '@atlaskit/tokens';

type IconProps = GlyphProps & {
	primaryColor?: IconColor;
	testId?: string;
};

export const Compact24Icon = (props: IconProps) => {
	const primaryColor = props.primaryColor || token('color.icon');
	const label = props.label || '';
	return <MenuIcon label={label} color={primaryColor} spacing="spacious" testId={props.testId} />;
};
